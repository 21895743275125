import { Link } from "gatsby"
import React, { useState } from "react"

import { useScroll } from "../hooks/use-scroll"
import Logo from "../icons/logo"
import BurgerMenuButton from "./burger-menu-button"

import styles from "../styles/header.module.css"

interface LinkProps {
  path: string
  children: React.ReactNode
}

interface Props {
  location: string
  full: boolean
}

const Header = ({ location, full }: Props) => {
  const { scrollY } = typeof window !== 'undefined' ?  useScroll() : { scrollY: 500 }
  let bgThreshold = full ? 0 : 300

  // States

  const [isMenuOpen, setIsMenuOpen] = useState(false)

  // Handlers

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  // Rendering

  let backgroundHeight = 0

  if (scrollY >= bgThreshold) {
    backgroundHeight = (scrollY - bgThreshold) * 0.3
    if (backgroundHeight > 100) backgroundHeight = 100
  }

  const NavLink = ({ path, children }: LinkProps) => <li className={location === path ? styles.active : ''}><Link to={path}>{children}</Link></li>

  return (
    <header className={`${styles.header} ${isMenuOpen ? styles.open : ''} ${full ? styles.full : ''}`}>
      <div className={styles.background} style={{height: `${backgroundHeight}%`}}/>

      <Link to="/"><Logo/></Link>
      <BurgerMenuButton close={isMenuOpen} onClick={toggleMenu} className={styles.burgerMenuButton}/>
      <ul>
        <NavLink path="/">Nos produits</NavLink>
        <NavLink path="a-propos">À propos</NavLink>
      </ul>
    </header>
  )
}

export default Header
