import React from "react"

import styles from "../styles/burger-menu-button.module.css"

interface Props {
    close?: boolean
    onClick: () => void
    className?: string
}

const BurgerMenuButton = ({ close = false, onClick, className = '' } : Props) => (
    <div onClick={onClick} className={`${styles.button} ${close ? styles.close : ''} ${className}`}>
        <span/>
        <span/>
        <span/>
    </div>
)

export default BurgerMenuButton
