import React from "react"
import { Link } from "gatsby"

import Logo from "../icons/logo"

import styles from "../styles/footer.module.css"

const Footer = () => (
  <footer className={styles.footer}>
      <div className={`container ${styles.footerContainer}`}>
        <div className={styles.col}>
            <div className={styles.logoHolder}><Link to="/"><Logo contrast/></Link><br/>© 2020 KPAJ inc.</div>
            <ul>
                <li><Link to="/">Nos produits</Link></li>
                <li><Link to="/a-propos">À propos</Link></li>
            </ul>
        </div>
        <div className={styles.col}>
            <p>Pour toute commande ou pour avoir des renseignements sur nos produits, veuillez contacter</p>
            <p className={styles.contactInfo}>
                <strong>Florent Côté</strong><br/>
                <strong>Président KPAJ, Consultant expert</strong><br/>
                <a href="mailto:florcote@gmail.com" target="_blank">florcote@gmail.com</a><br/>
                <a href="tel:5144735903" target="_blank">(514) 473-5903</a>
            </p>
            <p className={styles.contactInfo}>
                <strong>Sylvain Gagnon</strong><br/>
                <strong>Consultant expert</strong><br/>
                <a href="mailto:sgaginter@gmail.com" target="_blank">sgaginter@gmail.com</a><br/>
                <a href="tel:5147121218" target="_blank">(514) 712-1218</a>
            </p>
        </div>
      </div>
  </footer>
)

export default Footer
