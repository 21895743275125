import React from "react"
import PropTypes from "prop-types"

import Header from "./header"
import Footer from "./footer"
import "../styles/layout.css"

interface Props {
  location: string
  children: React.ReactNode
  theme?: string
}

const Layout = ({ children, location, theme }: Props) => (
  <div>
      <Header location={location} full={theme === "light"}/>
      <main>{children}</main>
      <Footer/>
  </div>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
